import React from "react";

import withLayout from "../../components/hoc/withLayout";

const Music = () => {
  return (
    <div className="my-12">
      <h1 className="text-center">Hey where's the Soundcloud link?</h1>
    </div>
  );
};

export default withLayout(Music);
